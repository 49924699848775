<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getOutOfStockInventoryReport"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
          <h2 class="col-md-10 col-sm-9 col-8 font-weight-normal d-flex  align-items-center">
            <span>{{ $t('management.txtreportByOutOfStock') }}</span>
          </h2>
            <div v-if="isExport" class="col-md-2 col-sm-3 col-4 text-right text-success">
              <CButton id="outOfStockExportButton" v-on:click="exportOutOfStockInventoryReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>  
            </div>
                <div class="col-md-10 col-sm-9 col-7">   
                  <show-date></show-date>
            </div>
          </div>

          <CRow>
            <CCol sm="12" lg="12">
              <sortdata v-on:getSort="setSort" :sort_value="sort_value" :sortdata="sortdata" />

              <hr />
              <DataTable id="outOfStockDatatable" :items="items" :fields="fields" hover border style="white-space:nowrap;"></DataTable>
              
            </CCol>
          </CRow>
          <pagination :meta_data="meta_data" v-on:next="getOutOfStockInventoryReport">
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>
  
  
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import Sortdata from '@/containers/SortData'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import SearchPattern from '@/containers/SearchPattern'
import util from '@/util/util'

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
    SearchPattern,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      searchLoadingButton: true,
      keyword: '',
      searchBy: '',
      skuType: '',
      sortdata: 'SKUName',
      lastupdate: '',
      sort_value: {
        name: 'SKUName',
        category: 'categoryName',
        onhandQtyMin: 'onhandQtyMin',
        onhandQtyMax: 'onhandQtyMax',

      },
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'permissions']),
    itempage() {
      return this.meta_data.current_page * 50 - 50
    },
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    startDate() {
      return moment(String(this.date.start)).format('DD MMMM YYYY')
    },
    endDate() {
      return moment(String(this.date.end)).format('DD MMMM YYYY')
    },
    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t(' # '),
          _style: 'width:5%',
          _classes: 'text-center text-dark font-weight-normal'
        },
        {
          key: 'PLUCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:20%',
          _classes: 'text-left font-weight-normal text-dark'
        },
        {
          key: 'name',
          label: this.$i18n.t('productName'),
          _style: 'width:40%',
          _classes: 'text-left font-weight-normal text-dark'
        },
        {
          key: 'unit',
          label: this.$i18n.t('unit'),
          _classes: 'text-left font-weight-normal text-dark '
        },
        {
          key: 'categoryName',
          label: this.$i18n.t('category'),
          _classes: 'text-left font-weight-normal text-dark '
        },
        {
          key: 'onhandQty',
          label: this.$i18n.t('onhandQty'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      const data = this.data
      let detail = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 50
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        let name = ''
        let unit = ''
        let onhandQty = 0
        let categoryName = ''

          const productPLU = item.ProductPLU || {}
          const PLUCode = productPLU.PLUCode || ''

        if (item.ProductSKUStock == undefined) {
          name = '-'
        } else {
          if (item.ProductSKUStock.SKUName == undefined) {
            name = '-'
          } else {
            name = item.ProductSKUStock.SKUName
          }

          if (item.ProductSKUStock.SKUUnit == undefined) {
            unit = '-'
          } else {
            unit = data[i].ProductSKUStock.SKUUnit
          }

          if (item.ProductSKUStock.categoryName == undefined) {
            categoryName = '-'
          } else {
            categoryName = item.ProductSKUStock.categoryName
          }
          const inactive = this.$t('inactiveStock')
          if (item.ProductSKUStock.onhandQty == undefined){
            onhandQty = inactive
          } else {
            onhandQty =  util.convertOnhand(item.ProductSKUStock.onhandQty)
          }

        }

        detail.push({
          id: (i + 1),
          PLUCode: PLUCode,
          name: name,
          unit: unit,
          categoryName: categoryName,
          onhandQty: onhandQty,
        })
      }
      return detail
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata

        if (sortdata == 'onhandQtyMin' || sortdata == 'onhandQtyMax') {
          sortdata = 'ProductSKUStock.onhandQty'
        }
        if (sortdata == 'categoryName') {
          sortdata = 'ProductSKUStock.categoryName'}
        return sortdata
      },
      set(newValue) {
        return newValue
      },
    },
  },
  created() {
    this.getOutOfStockInventoryReport()
  },
  methods: {
    setSort(value) {
      this.sortdata = value
      this.getOutOfStockInventoryReport()
    },
    getOutOfStockInventoryReport(page = 1) {
      this.loadingButton = false
      this.searchLoadingButton = false

      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const headers = { shopObjectId: shopObjectId }

      let sortOrder = ''
      if (this.sortdata == 'onhandQtyMin') {
        sortOrder = 'asc'
      } else if (
        
        this.sortdata == 'onhandQtyMax'
      ) {
        sortOrder = 'desc'
      } else {
        sortOrder = 'asc'
      }

      let params = {
        shopObjectId: shopObjectId,
        page: page,
        limit: 50,
        sortattr: this.sortAttr,
        sortorder: sortOrder,
      }

      // Search value.
      if (this.searchBy !== '') {
        params['searchVal'] = this.keyword
        params['searchAttr'] = this.searchBy
      }

      axios({
        url: '/inventory/v1.0/' + uid + '/productsku/emptystock',
        params: params,
        headers: headers,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.searchLoadingButton = true
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportOutOfStockInventoryReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan

      const params = {
        shopObjectId: shopObjectId,
        plan: plan,
      }
      const headers = { shopObjectId: shopObjectId }

      report({
        url: '/inventory/v1.0/' + uid + '/productsku/emptystock/excel',
        params: params,
        method: 'GET',
        headers: headers,
        responseType: 'blob', // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสินค้าหมด.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
}
</script>